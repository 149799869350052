import React from 'react'
import styled from 'styled-components'
import { ArrowBack } from 'styled-icons/boxicons-regular'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  background-color: #303540;
  padding: 5px;
  padding-left: 20px;
`

const BackIcon = styled(ArrowBack)`
  width: 18px;
  height: 18px;
  color: #fff;
`

const StyledLink = styled(Link)`
  display: flex;
  color: #fff;
  font-size: 12px;
`

export const LandingTopbar = () => (
  <Wrapper>
    <StyledLink to="/">
      <BackIcon />
      <span className="ml-2">Visite a vanna.app</span>
    </StyledLink>
  </Wrapper>
)
